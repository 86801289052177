// Styles
import "vuetify/styles";
import "@fortawesome/fontawesome-free/css/all.css";
import "@/styles/main.scss";

// Vuetify
import { createVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa";

export default createVuetify({
  icons: {
    defaultSet: "fa",
    aliases: {
      ...aliases,
      events:
        "svg:M 7.125 3 L 13.875 3 L 13.875 1.125 C 13.875 0.503906 14.375 0 15 0 C 15.625 0 16.125 0.503906 16.125 1.125 L 16.125 3 L 18 3 C 19.65625 3 21 4.34375 21 6 L 21 21 C 21 22.65625 19.65625 24 18 24 L 3 24 C 1.34375 24 0 22.65625 0 21 L 0 6 C 0 4.34375 1.34375 3 3 3 L 4.875 3 L 4.875 1.125 C 4.875 0.503906 5.375 0 6 0 C 6.625 0 7.125 0.503906 7.125 1.125 Z M 2.25 21 C 2.25 21.414062 2.585938 21.75 3 21.75 L 18 21.75 C 18.414062 21.75 18.75 21.414062 18.75 21 L 18.75 9 L 2.25 9 Z M 11.195312 11.285156 C 11.195312 11.03125 11.054688 10.796875 10.828125 10.675781 C 10.601562 10.554688 10.328125 10.566406 10.113281 10.710938 L 8.03125 12.097656 C 7.714844 12.308594 7.628906 12.738281 7.839844 13.0625 C 8.054688 13.382812 8.484375 13.464844 8.804688 13.253906 L 9.804688 12.582031 L 9.804688 18.917969 L 8.417969 18.917969 C 8.035156 18.917969 7.726562 19.230469 7.726562 19.613281 C 7.726562 19.996094 8.035156 20.308594 8.417969 20.308594 L 12.582031 20.308594 C 12.964844 20.308594 13.273438 19.996094 13.273438 19.613281 C 13.273438 19.230469 12.964844 18.917969 12.582031 18.917969 L 11.195312 18.917969 Z M 11.195312 11.285156",
    },
    sets: {
      fa,
    },
  },
  theme: {
    defaultTheme: "emblem",
    themes: {
      emblem: {
        dark: false,
        colors: {
          primary: "#79C2BC",
          // default
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
        },
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
