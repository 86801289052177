import { OfferCategory } from "@emblem/common";

const colors: Record<OfferCategory, string> = {
  restaurant: "#accdd8",
  bar: "#F9E784",
  leisure: "#E58F65",
  services: "#5BD793",
  alpedhuez: "#16347f",
  lesdeuxalpes: "#d9191f",
  lesrousses: "#E48D00",
};

export { colors };
